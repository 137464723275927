import { computePosition, autoPlacement, shift, offset, arrow } from '@floating-ui/dom';

document.addEventListener('DOMContentLoaded', () => {
  function updateTooltip(target, tooltip) {
    if (!!tooltip) {
      const tooltipPosition = tooltip.getAttribute('data-tooltip-position');
      const arrowElement = tooltip.querySelector('.tooltip__arrow');

      computePosition(target, tooltip, {
        placement: tooltipPosition ?? 'top',
        middleware: [
          offset(6),
          ...(tooltipPosition === 'vert' ? [
            autoPlacement({
              allowedPlacements: ['top', 'bottom'],
            }),
          ] : [
            autoPlacement(),
          ]),
          shift({ padding: 5 }),
          ...(!!arrowElement ? [
            arrow({ element: arrowElement })
          ] : []),
        ],
      }).then((data) => {
        const { x, y, placement, middlewareData } = data;

        Object.assign(tooltip.style, {
          left: `${x}px`,
          top: `${y}px`,
        });

        const staticSide = {
          top: 'bottom',
          right: 'left',
          bottom: 'top',
          left: 'right',
        }[placement.split('-')[0]];

        if (!!arrowElement) {
          const { x: arrowX, y: arrowY } = middlewareData.arrow;

          Object.assign(arrowElement.style, {
            left: arrowX != null ? `${arrowX}px` : '',
            top: arrowY != null ? `${arrowY}px` : '',
            right: '',
            bottom: '',
            [staticSide]: '-4px',
          });
        }
      });
    }
  }

  function showTooltip(e) {
    if (e.target.hasAttribute('data-present-tooltip')) {
      const tooltip = document.getElementById(e.target.getAttribute('data-present-tooltip'));
      tooltip.style.display = 'block';
      updateTooltip(e.target, tooltip);
    }
  }

  function hideTooltip(e) {
    if (e.target.hasAttribute('data-present-tooltip')) {
      const tooltip = document.getElementById(e.target.getAttribute('data-present-tooltip'));
      tooltip.style.display = '';
    }
  }

  const tooltips = document.body.querySelectorAll('[data-present-tooltip]')
  if (!!tooltips) {
    tooltips.forEach(tt => {
      tt.addEventListener('mouseenter', showTooltip);
      tt.addEventListener('mouseleave', hideTooltip);
      tt.addEventListener('focus', showTooltip);
      tt.addEventListener('blur', hideTooltip);
    });
  }

  function registerTooltips(tooltips) {
    if (!!tooltips) {
      tooltips.forEach(tt => {
        tt.addEventListener('mouseenter', showTooltip);
        tt.addEventListener('mouseleave', hideTooltip);
        tt.addEventListener('focus', showTooltip);
        tt.addEventListener('blur', hideTooltip);
      });
    }
  }
  window.registerTooltips = registerTooltips;
});

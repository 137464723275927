/**
 * Merge the DEFAULT_SETTINGS with the user defined options if specified
 * @param {Object} options The user defined options
 */
export function mergeOptions(initialOptions, customOptions) {
	const merged = customOptions;
	for(const prop in initialOptions) {
		if(merged.hasOwnProperty(prop)) {
			if(initialOptions[prop] !== null && initialOptions[prop].constructor === Object) {
				merged[prop] = mergeOptions(initialOptions[prop], merged[prop]);
			}
		} else {
			merged[prop] = initialOptions[prop];
		}
	}
	return merged;
}

/**
 * Stylize the Toast.
 * @param {Element} element The HTML element to stylize
 * @param {Object}  styles  An object containing the style to apply
 */
export function stylize(element, styles) {
	Object.keys(styles).forEach((style) => {
		if (!!element) element.style[style] = styles[style];
	});
}

export const toast = (() => {
	/**
	 * The Toast animation speed; how long the Toast takes to move to and from the screen
	 * @type {number}
	 */
	const TOAST_ANIMATION_SPEED = 300;

	const Transitions = {
		SHOW: {
			'-webkit-transition': 'opacity ' + TOAST_ANIMATION_SPEED + 'ms, -webkit-transform ' + TOAST_ANIMATION_SPEED + 'ms',
			'transition': 'opacity ' + TOAST_ANIMATION_SPEED + 'ms, transform ' + TOAST_ANIMATION_SPEED + 'ms',
			'opacity': '1',
			'-webkit-transform': 'translateY(-100%) translateZ(0)',
			'transform': 'translateY(-100%) translateZ(0)'
		},

		HIDE: {
			'opacity': '0',
			'-webkit-transform': 'translateY(150%) translateZ(0)',
			'transform': 'translateY(150%) translateZ(0)'
		}
	};

	/**
	 * The default Toast settings
	 * @type {Object}
	 */
	const DEFAULT_SETTINGS = {
		style: {
			main: {
				'z-index': '1000',
				'position': 'fixed',
        'top': '0',
        'right': '0',
			},
      mobile: {
        'z-index': '1000',
				'position': 'fixed',
        'right': '0',
        'top': '3rem',
        'width': '100%',
      }
		},
		settings: {
			duration: 3000,
		},
	};

	/**
	 * The queue of Toasts waiting to be shown
	 * @type {Array}
	 */
	const toastQueue = [];

	/**
	 * The toastStage. This is the HTML element in which the toast resides
	 * Getter and setter methods are available privately
	 * @type {HTMLElement}
	 */
	let toastStage;

	/**
	 * The Timeout object for animations.
	 * This should be shared among the Toasts, because timeouts may be cancelled e.g. on explicit call of hide()
	 * @type {Object}
	 */
	let timeout;

	/**
	 * The main Toast object
	 * @param {string} text The text to put inside the Toast
	 * @param {Object} options Optional; the Toast options. See Toast.prototype.DEFAULT_SETTINGS for more information
	 * @param {Object} transitions Optional; the Transitions object. This should not be used unless you know what you're doing
	 * @param {Object} __internalDefaultSettings For internal use only. Used for Snackbar.
	 */
	function toast(text, options, transitions) {
		const _transitions = transitions || Transitions;

		if (getToastStage() !== undefined) {
			// If there is already a Toast being shown, put this Toast in the queue to show later
			toastQueue.push({ text, options, transitions: _transitions });
		} else {
			let _options = options || {};
			_options = mergeOptions(DEFAULT_SETTINGS, _options);

			showToast(text, _options, _transitions);
		}

		return {
			hide: () => hideToast(_transitions),
		};
	}

	/**
	 * Show the Toast
	 * @param {string} text The text to show inside the Toast
	 * @param {Object} options The object containing the options for the Toast
	 */
	function showToast(text, options, transitions) {
		generateToast(text, options.style.main);

		const toastStage = getToastStage();
		document.body.insertBefore(toastStage, document.body.firstChild);

		// This is a hack to get animations started. Apparently without explicitly redrawing, it'll just attach the class and no animations would be done.
		toastStage.offsetHeight;

		stylize(toastStage, transitions.SHOW);

		// Hide the Toast after the specified time
		clearTimeout(timeout);
		if (options.settings.duration !== 0) {
			timeout = setTimeout(() => hideToast(transitions), options.settings.duration);
		}
	}

	/**
	 * Hide the Toast that's currently shown.
	 */
	function hideToast(transitions) {
		const toastStage = getToastStage();
		stylize(toastStage, transitions.HIDE);

		// Destroy the Toast element after animations end.
		clearTimeout(timeout);
    if (!!toastStage) toastStage.addEventListener('transitionend', destroyToast, { once: true });
	}

	/**
	 * Generate the Toast with the specified text.
	 * @param {string|HTMLElement} text The text to show inside the Toast, can be an HTML element or plain text
	 * @param {Object} style The style to set for the Toast
	 */
	function generateToast(text, style) {
		var toastStage = document.createElement('div');
    toastStage.innerHTML = `
      <div aria-live="assertive" class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
        <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
          <!--
            Notification panel, dynamically insert this into the live region when it needs to be displayed

            Entering: "transform ease-out duration-300 transition"
              From: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              To: "translate-y-0 opacity-100 sm:translate-x-0"
            Leaving: "transition ease-in duration-100"
              From: "opacity-100"
              To: "opacity-0"
          -->
          <div class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div class="p-4">
              <div class="flex items-start">
                <div class="flex-shrink-0">
                  <!-- Heroicon name: outline/check-circle -->
                  <svg class="h-6 w-6 text-green-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                  <p class="text-sm font-medium text-gray-900">${text}</p>
                </div>
                <div class="ml-4 flex-shrink-0 flex">
                  <button type="button" onclick="destroyToast()" class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span class="sr-only">Close</span>
                    <!-- Heroicon name: solid/x -->
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    `;


		setToastStage(toastStage);
    const isMobile = window.matchMedia("only screen and (max-width: 639px)").matches
    if (isMobile) {
      stylize(getToastStage(), DEFAULT_SETTINGS.style.mobile);
    } else {
		  stylize(getToastStage(),style);
    }
	}

	/**
	 * Clean up after the Toast slides away. Namely, removing the Toast from the DOM.
	 * After the Toast is cleaned up, display the next Toast in the queue if any exists.
	 */
	function destroyToast() {
		const toastStage = getToastStage();

		document.body.removeChild(toastStage);
		setToastStage(undefined);

		if(toastQueue.length > 0) {
			// Show the rest of the Toasts in the queue if they exist.
			const newToast = toastQueue.shift();
			toast(newToast.text, newToast.options, newToast.transitions);
		}
	}
  window.destroyToast = destroyToast;

	function getToastStage() {
		return toastStage;
	}

	function setToastStage(newToastStage) {
		toastStage = newToastStage;
	}

	return { toast };
})();

document.addEventListener('DOMContentLoaded', () => {
  document.body.addEventListener('click', (e) => {
    if (e.target.hasAttribute('data-toast')) {
      toast.toast(e.target.getAttribute('data-toast'), {
        style: {
          main: {
            width: '24rem',
          },
        },
      });
    }
  }, true);
});

document.addEventListener('DOMContentLoaded', () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');

  let version;
  if (msie > 0) {
    // IE 10 or older => return version number
    version =  10
  }

  let trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    version =  11
  }

  let edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    version =  parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  if (version && version < 12) {
    const warning = document.createElement('div');
    warning.className = 'ie-error';
    warning.innerHTML = 'This site does not support Internet Explorer. Our preferred browser is Chrome but Firefox, Edge, or Safari will also work.';

    document.body.insertBefore(warning, document.body.childNodes[0]);
  }
});

try {
require("@rails/ujs").start()
}catch(e){
}
require("../detect-ie")
require("../attach-session-classes")
require("../utilities.js")
require("../presentable.js")
require("../present-tooltip.js")
require("../toast.js")
require("../topshops/carousel")

document.addEventListener('DOMContentLoaded', () => {
  /**
   * class list controls
   */
  function handleAddClass(e) {
    if (!!e.target.hasAttribute('data-class-add')) {
      const [selector, ...classNames] = e.target.getAttribute('data-class-add').split(' ').filter(Boolean);
      const ele = document.body.querySelector(selector ?? '');
      if (!!ele) ele.classList.add(classNames ?? '');
    }
  }
  function handleRemoveClass(e) {
    if (!!e.target.hasAttribute('data-class-remove')) {
      const [selector, ...classNames] = e.target.getAttribute('data-class-remove').split(' ').filter(Boolean);
      const ele = document.body.querySelector(selector ?? '');
      if (!!ele) ele.classList.remove(classNames ?? '');
    }
  }
  function handleToggleClass(e) {
    if (!!e.target.hasAttribute('data-class-toggle')) {
      const [selector, ...classNames] = e.target.getAttribute('data-class-toggle').split(' ').filter(Boolean);
      const ele = document.body.querySelector(selector ?? '');
      if (!!ele) classNames.forEach(c => ele.classList.toggle(c ?? ''));
    }
  }
  function handleReplaceClass(e) {
    if (!!e.target.hasAttribute('data-class-replace')) {
      const [selector, className1, className2] = e.target.getAttribute('data-class-replace').split(' ').filter(Boolean);
      const ele = document.body.querySelector(selector ?? '');
      if (!!ele) ele.classList.replace(className1 ?? '', className2 ?? '');
    }
  }
  function handleSwitchClass(e) {
    if (!!e.target.hasAttribute('data-class-switch')) {
      const [className1, className2] = e.target.getAttribute('data-class-switch').split(' ').filter(Boolean);
      if (!!className1) {
        const ele1 = document.body.querySelector(`.${className1}`);
        const ele2 = document.body.querySelector(`.${className2}`);
        if (!!ele1) {
          ele1.classList.replace(className1 ?? '', className2 ?? '');
        } else if (!!ele2) {
          ele2.classList.replace(className2 ?? '', className1 ?? '');
        }
      }
    }
  }

  /**
   * view visibility controls
   */
  function handleHide(e) {
    if (!!e.target.hasAttribute('data-hide')) {
      const ele = document.body.querySelector(e.target.getAttribute('data-hide'));
      if (!!ele) ele.classList.add('hidden');
    }
  }
  function handleToggle(e) {
    if (!!e.target.hasAttribute('data-toggle')) {
      const ele = document.body.querySelector(e.target.getAttribute('data-toggle'));
      if (!!ele) ele.classList.toggle('hidden');
    }
  }
  function handleShow(e) {
    if (!!e.target.hasAttribute('data-show')) {
      const ele = document.body.querySelector(e.target.getAttribute('data-show'));
      if (!!ele) ele.classList.remove('hidden');
    }
  }

  /**
   * url controls (no reload)
   */
  function setUrlParam(e) {
    if (!!e.target.hasAttribute('data-set-url-param')) {
      const params = e.target.getAttribute('data-set-url-param').split(' ').filter(Boolean);
      const url = new URL(window.location);
      url.searchParams.set(...params);
      window.history.replaceState(null, '', url);
    }
  }

  document.body.addEventListener('click', handleAddClass, false);
  document.body.addEventListener('click', handleRemoveClass, false);
  document.body.addEventListener('click', handleToggleClass, false);
  document.body.addEventListener('click', handleSwitchClass, false);
  document.body.addEventListener('click', handleReplaceClass, false);
  document.body.addEventListener('click', handleHide, false);
  document.body.addEventListener('click', handleToggle, false);
  document.body.addEventListener('click', handleShow, false);
  document.body.addEventListener('click', setUrlParam, false);
});

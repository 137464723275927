import { computePosition, flip, shift } from '@floating-ui/dom';
import { hash } from 'immutable';

/**
 *    Presentable is an element that runs tailwind animations on
 *    show/hide transitions and/or executes js during the transitions.
 *    Attempt to fill the gap for https://headlessui.dev/
 *
 *    data-presentable (allows for initialization and data caching via js)
 *    data-presentable-type="<type>" (optional type can give selective treatment in handling)
 *    data-dismissable="false" (stops from dismissing during cleanup)
 *
 *    > attributes to execute transitions
 *
 *    data-present="<id>"
 *    data-dismiss="<id>"
 *    data-dismiss (dismisses closest parent with [data-presentable])
 *
 *    > lifecycle handlers fire js during transition
 *
 *    data-will-present="<function_name> <...args>" (fires before present transition)
 *    data-did-present="<function_name> <...args>"  (fires after present transition)
 *    data-will-dismiss="<function_name> <...args>" (fires before dismiss transition)
 *    data-did-dismiss="<function_name> <...args>"  (fires after dismiss transition)
 *
 *    > transitions add classnames at the correct time
 *
 *    data-transition-none (stops all transitions)
 *    data-transition-present="<...classNames>"
 *    data-transition-present-from="<...classNames>"
 *    data-transition-present-to="<...classNames>"
 *    data-transition-present-delay="<milliseconds>"
 *    data-transition-dismiss="<...classNames>"
 *    data-transition-dismiss-from="<...classNames>"
 *    data-transition-dismiss-to="<...classNames>"
 *    data-transition-dismiss-delay="<milliseconds>"
 *
 *    > shorthand
 *
 *    data-transition="<...classNames>" (applied to data-transition-present & data-transition-dismiss)
 *    data-transition-from="<...classNames>" (applied to data-transition-present-from & data-transition-dismiss-to)
 *    data-transition-to="<...classNames>" (applied to data-transition-present-to & data-transition-dismiss-from)
 *
 *    > shorthand overrides via show/hide attributes
 *
 *    data-present + data-transition="<...classNames>" (applied to data-transition-present)
 *    data-present + data-transition-from="<...classNames>" (applied to data-transition-present-from)
 *    data-present + data-transition-to="<...classNames>" (applied to data-transition-present-to)
 *
 *    data-dismiss + data-transition="<...classNames>" (applied to data-transition-dismiss)
 *    data-dismiss + data-transition-from="<...classNames>" (applied to data-transition-dismiss-from)
 *    data-dismiss + data-transition-to="<...classNames>" (applied to data-transition-dismiss-to)
 *
 *    > js functions on window
 *
 *    window.createPresentable(<html>)
 *    window.onWillPresent(<id>, <handler>)
 *    window.onDidPresent(<id>, <handler>)
 *    window.onWillDismiss(<id>, <handler>)
 *    window.onDidDismiss(<id>, <handler>)
 *    window.present(<id>, <options>)
 *    window.dismiss(<id>, <options>)
 **/

document.addEventListener('DOMContentLoaded', () => {
  /**
   *  primarily for caching
   *
   *  state = {
   *    [modal_id]: {
   *      type: string,
   *      active: boolean,
   *      lifecycleHandlers: {
   *        willPresent: (ele) => {...},
   *        didPresent: (ele) => {...},
   *        willDismiss: (ele) => {...},
   *        didDismiss: (ele) => {...},
   *      },
   *      transitionData: {
   *        present: string || 'none',
   *        presentFrom: string || 'none',
   *        presentTo: string || 'none',
   *        presentDelay: number || 0,
   *        dismiss: string || 'none',
   *        dismissFrom: string || 'none',
   *        dismissTo: string || 'none',
   *        dismissDelay: number || 0,
   *      },
   *    },
   *  };
   * */
  let state = {};

  function initializePresentables() {
    const allPresentables = document.body.querySelectorAll('[data-presentable]');
    allPresentables.forEach(ele => {
      const presentableName = ele.getAttribute('data-presentable');

      state = {
        ...state,
        [ele.id]: {
          ...state[ele.id],
          name: ele.getAttribute('data-presentable'),
          type: ele.getAttribute('data-presentable-type'),
          isDismissable: ele.getAttribute('data-dismissable') !== 'false',
          dismissOnBlur: ele.getAttribute('data-dismiss-on-blur') !== 'false',
          group:  ele.getAttribute('data-presentable-group'),
          active: !ele.classList.contains('hidden'),
          lifecycleHandlers: getLifecycleHandlers(ele),
          transitionData: getTransitionData(ele, false),
        },
      };

      let hashValue = '';
      if (window.location.hash.indexOf('_') === 1) {
        hashValue = window.location.hash.slice(2);
      } else {
        hashValue = window.location.hash.slice(1);
      }


      if (
        (!!presentableName && presentableName === hashValue)
        || (hashValue === 'profile' && presentableName === 'login')
        || (hashValue === 'login' && presentableName === 'profile')
        || (hashValue === 'signup' && presentableName === 'profile')
        || (hashValue === 'signup' && presentableName === 'profile')
        || (hashValue === 'delete-account' && (presentableName === 'profile' || presentableName === 'login'))
        || (location.pathname.match("/referral/[^\/]*\/?$") && presentableName === 'signup')
      ) {
        showPresentable(ele);

        if (hashValue === 'delete-account') {
          document.body.classList.add('x-hash-delete-account');
        }
      }
    });
    console.debug('presentable state', state)
  }

  function registerPresentable(id) {
    const ele = document.getElementById(id);

    state = {
      ...state,
      [ele.id]: {
        ...state[ele.id],
        name: ele.getAttribute('data-presentable'),
        type: ele.getAttribute('data-presentable-type'),
        isDismissable: ele.getAttribute('data-dismissable') !== 'false',
        dismissOnBlur: ele.getAttribute('data-dismiss-on-blur') !== 'false',
        group:  ele.getAttribute('data-presentable-group'),
        active: !ele.classList.contains('hidden'),
        lifecycleHandlers: getLifecycleHandlers(ele),
        transitionData: getTransitionData(ele, false),
      },
    };
    console.debug(`register ${id}`);
  }

  function registerAllPresentables(id) {
    const division = document.getElementById(id);
    const allPresentables = division.querySelectorAll('[data-presentable]');

    allPresentables.forEach(ele => registerPresentable(ele.id));
    console.debug('registered all', state);
  }

  function unregisterPresentable(id) {
    delete state[id];
    console.debug(`unregister ${id}`);
  }

  function unregisterAllPresentables(id) {
    const division = document.getElementById(id);
    const allPresentables = division.querySelectorAll('[data-presentable]');

    allPresentables.forEach(ele => unregisterPresentable(ele.id));
    console.debug('unregistered all', state);
  }

  function hideAllPresentables(originEle) {
    console.debug('hideAllPresentables', originEle.id);
    for (const currPresentableId in state) {
      const currPresentable = state[currPresentableId];
      const ele = document.getElementById(currPresentableId);
      const originGroup = state[originEle.id]?.group ?? '';

      if (currPresentable.active && currPresentable.isDismissable && (!originGroup|| originGroup !== currPresentableId)) {
        const lifecycleHandlers = currPresentable.lifecycleHandlers;

        onLifecycle(ele, lifecycleHandlers?.willDismiss);
        hidePresentable(ele);
        onLifecycle(ele, lifecycleHandlers?.didDismiss);
      }
    }
  }

  function dismissAllPresentables(override = false) {
    console.debug('dismissAllPresentables');
    for (const currPresentableId in state) {
      const currPresentable = state[currPresentableId];
      const ele = document.getElementById(currPresentableId);

      if (currPresentable.active && (override || state[ele.id].isDismissable)) {
        const lifecycleHandlers = state[ele.id].lifecycleHandlers;
        const transitionData = state[ele.id].transitionData;
        handleDismiss(ele, { lifecycleHandlers, transitionData });
      }
    }
  }

  function showPresentable(ele) {
    console.debug('show', ele.id);
    ele.classList.remove('hidden');

    if (state[ele.id].type === 'modal') {
      setAppScroll(false);
    }

    state = {
      ...state,
      [ele.id]: {
        ...state[ele.id],
        active: true,
      }
    };
  }

  function hidePresentable(ele) {
    console.debug('hide', ele.id);
    ele.classList.add('hidden');
    removeHash();

    state = {
      ...state,
      [ele.id]: {
        ...state[ele.id],
        active: false,
      }
    };

    if (Object.values(state).every(entry => !entry.active)) {
      setAppScroll(true);
    }
  }

  function handlePresent(ele, { lifecycleHandlers, transitionData, dismissable = true, disableToggle = false }) {
    console.debug('handle present', ele.id);
    onLifecycle(ele, lifecycleHandlers?.willPresent);

    if (!ele.classList.contains('hidden') && !disableToggle) {
      handleDismiss(ele, { lifecycleHandlers, transitionData });
      return;
    }
    if (dismissable) hideAllPresentables(ele);

    addTransitionClasses(ele, transitionData?.present);
    addTransitionClasses(ele, transitionData?.presentFrom);

    showPresentable(ele);

    // hack to force render
    setTimeout(() => {
      addTransitionClasses(ele, transitionData?.presentTo);

      //cleanup classes after animation
      setTimeout(() => {
        removeTransitionClasses(ele, transitionData?.present);
        removeTransitionClasses(ele, transitionData?.presentFrom);
        removeTransitionClasses(ele, transitionData?.presentTo);

        onLifecycle(ele, lifecycleHandlers?.didPresent);
      }, transitionData?.presentDelay ?? 0);
    });
  }

  function handleDismiss(ele, { lifecycleHandlers, transitionData, disableToggle = false }) {
    console.debug('handle dismiss', ele.id);
    onLifecycle(ele, lifecycleHandlers?.willDismiss);

    if (ele.classList.contains('hidden') && !disableToggle) {
      handlePresent(ele, { lifecycleHandlers, transitionData });
      return;
    }

    addTransitionClasses(ele, transitionData?.dismiss);
    addTransitionClasses(ele, transitionData?.dismissFrom);
    addTransitionClasses(ele, transitionData?.dismissTo);

    // hack to force render
    setTimeout(() => {
      removeTransitionClasses(ele, transitionData?.dismissFrom);

      // cleanup classes after animation
      setTimeout(() => {
        hidePresentable(ele);

        removeTransitionClasses(ele, transitionData?.dismiss);
        removeTransitionClasses(ele, transitionData?.dismissTo);

        onLifecycle(ele, lifecycleHandlers?.didDismiss);
      }, transitionData?.dismissDelay ?? 0);
    });
  }

  function createPresentable(partial, parentSelector = 'body') {
    const tmp = document.createElement('div');
    tmp.innerHTML = partial;
    const newPresentable = tmp.firstElementChild;

    const potentialEle = document.getElementById(newPresentable.id);
    if (!!potentialEle) potentialEle.remove();

    const parent = document.querySelector(parentSelector);
    const node = parent.appendChild(newPresentable);

    state = {
      ...state,
      [newPresentable.id]: {
        ...state[newPresentable.id],
        name: newPresentable.getAttribute('data-presentable'),
        type: newPresentable.getAttribute('data-presentable-type'),
        isDismissable: newPresentable.getAttribute('data-dismissable') !== 'false',
        dismissOnBlur: newPresentable.getAttribute('data-dismiss-on-blur') !== 'false',
        group:  newPresentable.getAttribute('data-presentable-group'),
        active: !newPresentable.classList.contains('hidden'),
        lifecycleHandlers: getLifecycleHandlers(newPresentable),
        transitionData: getTransitionData(newPresentable, false),
      },
    };

    return node;
  }

  function updateLifecycleHandler(lifecycleType) {
    return (id, handler) => {
      const ele = document.getElementById(id);
      if (!!ele) {
        state = {
          ...state,
          [id]: {
            ...state[id],
            lifecycleHandlers: {
              ...state[id].lifecycleHandlers,
              [lifecycleType]: handler,
            },
          },
        };
      }
    }
  }

  function present(id, optionOverrides) {
    setTimeout(() => {
      const ele = document.getElementById(id);
      if (!!ele) {
        console.debug('window.present()');
        const lifecycleHandlers = optionOverrides?.lifecycleHandlers ?? state[ele.id].lifecycleHandlers;
        const transitionData = optionOverrides?.transitionData ?? state[ele.id].transitionData;
        const dismissable = optionOverrides?.dismissable ?? true;
        const disableToggle = optionOverrides?.disableToggle ?? false;
        handlePresent(ele, { lifecycleHandlers, transitionData, dismissable, disableToggle });
      }
    });
  }

  function dismiss(id, optionOverrides) {
    setTimeout(() => {
      const ele = document.getElementById(id);
      if (!!ele) {
        console.debug('window.dismiss()');
        const lifecycleHandlers = optionOverrides?.lifecycleHandlers ?? state[ele.id].lifecycleHandlers;
        const transitionData = optionOverrides?.transitionData ?? state[ele.id].transitionData;
        const dismissable = optionOverrides?.dismissable ?? state[ele.id].isDismissable;
        const disableToggle = optionOverrides?.disableToggle ?? false;
        handleDismiss(ele, { lifecycleHandlers, transitionData, dismissable, disableToggle });
      }
    });
  }

  document.body.addEventListener('click', (e) => {
    const hasDataPresent = e.target.hasAttribute('data-present');
    const hasDataDismiss = e.target.hasAttribute('data-dismiss');

    if (hasDataPresent || hasDataDismiss) {
      // handle [data-present] click
      if (hasDataPresent) {
        const value = e.target.getAttribute('data-present');
        const ele = document.getElementById(value);
        if (!!ele) {
          console.debug('[data-present]');

          if (ele.hasAttribute('data-auto-placement')) {
            computePosition(e.target, ele, {
              placement: ele.getAttribute('data-auto-placement') || 'bottom',
              middleware: [flip(), shift({ padding: 5 })],
            }).then(({ x, y }) => {
              Object.assign(ele.style, {
                left: `${x}px`,
                top: `${y}px`,
              });
            });
          }

          const lifecycleHandlers = state[ele.id].lifecycleHandlers;
          const transitionData = { ...state[ele.id].transitionData, ...getTransitionData(e.target, true) };
          const dismissable = state[ele.id].isDismissable;
          console.log({ lifecycleHandlers, transitionData, dismissable })
          handlePresent(ele, { lifecycleHandlers, transitionData, dismissable });
        }
      }

      // handle [data-dismiss] click
      if (hasDataDismiss) {
        const value = e.target.getAttribute('data-dismiss');
        const ele = !!value ? document.getElementById(value) : e.target.closest('[data-presentable]');
        if (!!ele) {
          console.debug('[data-dismiss]');
          const lifecycleHandlers = state[ele.id].lifecycleHandlers;
          const transitionData = { ...state[ele.id].transitionData, ...getTransitionData(e.target, true) };
          handleDismiss(ele, { lifecycleHandlers, transitionData });
        }
      }
    } else {
      // handle external clicks to dismiss presentables
      if (Object.values(state).some(entry => entry.active)) {
        const closestPresentable = e.target.closest('[data-presentable]');

        for (const currPresentableId in state) {
          const currPresentable = state[currPresentableId];

          if (currPresentable.active && currPresentable.dismissOnBlur) {
            const clickIsOutsidePresentable = !closestPresentable;
            const currPresentableIsClosestObservable = closestPresentable?.id === currPresentableId;

            if (clickIsOutsidePresentable || !currPresentableIsClosestObservable) {
              const ele = document.getElementById(currPresentableId);
              if (!!ele) {
                const lifecycleHandlers = state[ele.id].lifecycleHandlers;
                const transitionData = state[ele.id].transitionData;
                handleDismiss(ele, { lifecycleHandlers, transitionData });
              }
            }
          }
        }
      }
    }
  }, false);

  window.getPresentableState = () => console.log(state); // for debugging
  window.getPresentableStateIds = () => console.log(Object.keys(state)); // for debugging
  window.isPresentableActive = () => Object.values(state).some(entry => entry.active);
  window.createPresentable = createPresentable;
  window.onWillPresent = updateLifecycleHandler('willPresent');
  window.onDidPresent = updateLifecycleHandler('didPresent');
  window.onWillDismiss = updateLifecycleHandler('willDismiss');
  window.onDidDismiss = updateLifecycleHandler('didDismiss');
  window.present = present;
  window.dismiss = dismiss;

  /* handle esc key */
  document.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') dismissAllPresentables(true);
  }, false);

  initializePresentables();
  window.registerAllPresentables = registerAllPresentables;
  window.unregisterAllPresentables = unregisterAllPresentables;
  window.registerPresentable = registerPresentable;
  window.unregisterPresentable = unregisterPresentable;


  // START: utilities
  function getLifecycleHandlers(ele) {
    const dataWillPresent = ele.getAttribute('data-will-present') ?? '';
    const dataDidPresent = ele.getAttribute('data-did-present') ?? '';
    const dataWillDismiss = ele.getAttribute('data-will-dismiss') ?? '';
    const dataDidDismiss = ele.getAttribute('data-did-dismiss') ?? '';

    const [willPresentFunctionName, ...willPresentArgs] = dataWillPresent.split(' ').filter(Boolean);
    const [didPresentFunctionName, ...didPresentArgs] = dataDidPresent.split(' ').filter(Boolean);
    const [willDismissFunctionName, ...willDismissArgs] = dataWillDismiss.split(' ').filter(Boolean);
    const [didDismissFunctionName, ...didDismissArgs] = dataDidDismiss.split(' ').filter(Boolean);

    return {
      willPresent: () => !!willPresentFunctionName && window[willPresentFunctionName](...willPresentArgs),
      didPresent: () => !!didPresentFunctionName && window[didPresentFunctionName](...didPresentArgs),
      willDismiss: () => !!willDismissFunctionName && window[willDismissFunctionName](...willDismissArgs),
      didDismiss: () => !!didDismissFunctionName && window[didDismissFunctionName](...didDismissArgs),
    };
  }

  function getTransitionDelay(transitionData) {
    if (!!transitionData && transitionData !== 'none') {
      const transitionDataArr = transitionData.split(' ').filter(Boolean);
      for (let i = 0; i < transitionDataArr.length; i++) {
        const style = transitionDataArr[i];
        if (style.startsWith('duration-')) {
          return style.split('-')[1];
        }
      }
    }
    return 0;
  }

  function getTransitionData(ele, filterDefaults) {
    if (ele.hasAttribute('data-transition-none')) {
      return {
        present: 'none',
        presentFrom: 'none',
        presentTo: 'none',
        presentDelay: 0,
        dismiss: 'none',
        dismissFrom: 'none',
        dismissTo:  'none',
        dismissDelay: 0,
      };
    }

    const isPresentable = ele.hasAttribute('data-presentable');
    const isPresentAction = ele.hasAttribute('data-present');
    const isDismissAction = ele.hasAttribute('data-dismiss');

    const dataTranisition = ele.getAttribute('data-transition');
    const dataTranisitionFrom = ele.getAttribute('data-transition-from');
    const dataTranisitionTo = ele.getAttribute('data-transition-to');
    const dataTranisitionDelay = ele.getAttribute('data-transition-delay');
    const dataTranisitionPresent = ele.getAttribute('data-transition-present');
    const dataTranisitionPresentFrom = ele.getAttribute('data-transition-present-from');
    const dataTranisitionPresentTo = ele.getAttribute('data-transition-present-to');
    const dataTranisitionPresentDelay = ele.getAttribute('data-transition-present-delay');
    const dataTranisitionDismiss = ele.getAttribute('data-transition-dismiss');
    const dataTranisitionDismissFrom = ele.getAttribute('data-transition-dismiss-from');
    const dataTranisitionDismissTo = ele.getAttribute('data-transition-dismiss-to');
    const dataTranisitionDismissDelay = ele.getAttribute('data-transition-dismiss-delay');

    const data = {
      ...((isPresentable || isPresentAction) ? {
        present: dataTranisitionPresent || dataTranisition || 'none',
        presentFrom: dataTranisitionPresentFrom || dataTranisitionFrom || 'none',
        presentTo: dataTranisitionPresentTo || dataTranisitionTo || 'none',
        presentDelay: dataTranisitionPresentDelay || dataTranisitionDelay || getTransitionDelay(dataTranisitionPresent ?? dataTranisition),
      } : {}),
      ...((isPresentable) ? { // to and from are switched on the generic attribute
        dismiss: dataTranisitionDismiss || dataTranisition || 'none',
        dismissFrom: dataTranisitionDismissFrom || dataTranisitionTo || 'none',
        dismissTo: dataTranisitionDismissTo || dataTranisitionFrom || 'none',
        dismissDelay: dataTranisitionDismissDelay || dataTranisitionDelay || getTransitionDelay(dataTranisitionDismiss ?? dataTranisition),
      } : {}),
      ...((isDismissAction) ? {
        dismiss: dataTranisitionDismiss || dataTranisition || 'none',
        dismissFrom: dataTranisitionDismissFrom || dataTranisitionFrom || 'none',
        dismissTo: dataTranisitionDismissTo || dataTranisitionTo || 'none',
        dismissDelay: dataTranisitionDismissDelay || dataTranisitionDelay || getTransitionDelay(dataTranisitionDismiss ?? dataTranisition),
      } : {}),
    };

    if (filterDefaults) {
      return Object.fromEntries(
        Object.values(data).filter((val) => {
          return val !== 'none' && val !== 0;
        })
      );
    }
    return data;
  }

  function onLifecycle(ele, handler) {
    if (!!handler) { handler(ele); }
  }

  function addTransitionClasses(ele, transitionData) {
    if (!!transitionData && transitionData !== 'none') {
      const transitionDataArr = transitionData.split(' ').filter(Boolean);
      ele.classList.add(...transitionDataArr);
    }
  }

  function removeTransitionClasses(ele, transitionData) {
    if (!!transitionData && transitionData !== 'none') {
      const transitionDataArr = transitionData.split(' ').filter(Boolean);
      ele.classList.remove(...transitionDataArr);
    }
  }

  function setAppScroll(enable) {
    if (enable) {
      document.body.classList.remove('overflow-hidden');
    } else {
      document.body.classList.add('overflow-hidden');
    }
  }
  window.setAppScroll = setAppScroll;

  function removeHash() {
    history.replaceState('', document.title, `${window.location.pathname}${window.location.search}`);
  }
  // END: utilities
});
